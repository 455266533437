import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useEffect, useState } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import Modal from "react-modal"
import { useParams, useSearchParams } from "react-router-dom"
import { NotFound, ErrorPage } from "./ErrorPage"
import cls from "classnames"

Modal.setAppElement("#root")

const REQUIRED_FIELDS = ["name_first", "name_last", "phone"]

const http = axios.create({
  baseURL: "/api/v0/hello-page-preferences/",
})

export function SubmissionSuccessModal({
  isOpen,
  message,
  onClose,
}: {
  isOpen: boolean
  message: string
  onClose: () => void
}) {
  return (
    <Modal
      style={{
        content: {
          maxWidth: "80%",
          width: "540px",
          height: "fit-content",
          maxHeight: "100vh",
          margin: "auto",
          padding: "10px 20px",
        },
      }}
      isOpen={isOpen}
    >
      <div className="text-ms-dark-blue-80 flex w-full">
        <button className="ml-auto" onClick={onClose}>
          <h2 className="text-3xl font-extrabold mb-3">×</h2>
        </button>
      </div>
      <div className="text-ms-dark-blue-80 mt-6 mb-8 flex flex-col items-center">
        <span>{message}</span>
      </div>
    </Modal>
  )
}

function PrivacyPolicy() {
  return (
    <div className="text-xs mt-8">
      By submitting your phone number, you accept the{" "}
      <a
        className="text-ms-link-green font-semibold hover:underline"
        target="_blank"
        rel="noreferrer"
        href="https://mainstay.com/legal-chatbotprivacypolicy/"
      >
        Privacy Policy
      </a>{" "}
      and{" "}
      <a
        className="text-ms-link-green font-semibold hover:underline"
        target="_blank"
        rel="noreferrer"
        href="https://mainstay.com/admithub-chatbot-terms/"
      >
        Terms of Use
      </a>
      . Msg & data rates may apply. Msg frequency varies. Unsubscribe at any
      time by replying STOP.
    </div>
  )
}

type TFormFieldTopLevelResponse = {
  readonly top_level_field: string
  readonly contact_attribute: null
  readonly display_field_name: string
  readonly default_field_value: string
  readonly order: number
  readonly field_type: string
  readonly multi_choice_options: null
  readonly visible: boolean
}
type TFormFieldContactAttributeResponse = {
  readonly top_level_field: null
  readonly contact_attribute: number
  readonly display_field_name: string
  readonly default_field_value: string
  readonly order: number
  readonly field_type: string
  readonly multi_choice_options: string[] | null
  readonly visible: boolean
}

function ConsentCheckBox({ consentText }: { consentText: string }) {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ [x: string]: string }>()
  return (
    <div className="flex flex-row items-start mt-4">
      <input
        className="cursor-pointer mr-3 h-[19.2px]"
        type="checkbox"
        id="consent"
        {...register("consent", {
          min: {
            value: 1,
            message: "Please check the consent box",
          },
        })}
      />
      <div>
        <div className="flex flex-row items-start">
          <label className="text-xs font-semibold" htmlFor="consent">
            {consentText}
          </label>
        </div>
        <div className="text-xs text-ms-error-red font-semibold mt-2">
          {errors["consent"] && <span>{errors["consent"]?.message}</span>}
        </div>
      </div>
    </div>
  )
}

type TFormField =
  | TFormFieldTopLevelResponse
  | TFormFieldContactAttributeResponse

function FormField({
  field,
  fieldType,
  defaultValue,
  displayName,
  multiChoiceOptions,
  isRequired,
  visible,
}: {
  field: string
  fieldType: string
  defaultValue: string
  displayName: string
  multiChoiceOptions: string[] | null
  isRequired: boolean
  visible: boolean
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ [x: string]: string }>()
  const [searchParams] = useSearchParams()

  const inputStyle = cls(
    "border bg-white block rounded border-1 border-slate-300 w-full",
    { "border-ms-error-red": errors[field] }
  )

  return (
    <div className={cls({ "py-2": visible })}>
      {visible && (
        <label className="text-xs font-bold" htmlFor={field}>
          {displayName} {isRequired && " *"}
        </label>
      )}
      {fieldType === "MULTI_CHOICE" && multiChoiceOptions ? (
        <select
          className={cls(
            inputStyle,
            "pl-2 py-2 -webkit-padding-start-4px h-[42px]"
          )}
          id={field}
          {...register(field)}
        >
          {multiChoiceOptions.map((elem) => (
            <option key={elem} value={elem}>
              {elem}
            </option>
          ))}
        </select>
      ) : (
        <input
          className={cls(inputStyle, "p-2")}
          type={visible ? "text" : "hidden"}
          defaultValue={
            // for hidden fields pull the value if exists from the search params
            visible
              ? defaultValue
              : searchParams.get(displayName) ?? defaultValue
          }
          id={field}
          {...register(field, getFieldValidation(fieldType, isRequired))}
        />
      )}
      <div className="text-xs text-ms-error-red font-semibold">
        {errors[field] && <span>{errors[field]?.message}</span>}
      </div>
    </div>
  )
}

type TFormImage = {
  readonly href: string
  readonly name: string
}

type TFormFieldIcon = {
  readonly href: string
  readonly name: string
}

type TFormFields = {
  readonly id: number
  readonly page_title: string
  readonly url_path: string
  readonly dialog_id: null | string
  readonly confirmation_message: string
  readonly icon?: TFormFieldIcon
  readonly form_image?: TFormImage
  readonly form_header: string
  readonly form_description: string
  readonly consent_text: string
  readonly attribute_fields: ReadonlyArray<TFormField>
}

export function HelloPageForm() {
  const formHelper = useForm()
  const { urlPath } = useParams()
  const { data, isLoading, isError, error } = useQuery(
    ["product", urlPath],
    () => formFetcher(urlPath ?? "")
  )
  const [submissionStatus, setSubmissionsStatus] = useState<
    "success" | "failure" | undefined
  >()

  useEffect(() => {
    if (data?.page_title) {
      document.title = data.page_title
    }
  }, [data?.page_title])

  useEffect(() => {
    if (!submissionStatus) {
      return
    }
    if (submissionStatus === "failure" && formHelper.formState.isDirty) {
      setSubmissionsStatus(undefined)
    }
  }, [submissionStatus, formHelper.formState.isDirty])

  if (isError) {
    if (axios.isAxiosError(error) && error.status === 404) {
      return <NotFound />
    }
    return <ErrorPage errorMessage="Something went wrong" />
  }

  if (isLoading || !data) {
    return <div></div>
  }

  const onSubmit = (formData: Record<string, number | string>) =>
    saveContact(
      mapFormDataToPayload(formData)(
        data.id,
        data.attribute_fields.map((elem) => elem.contact_attribute)
      )
    )
      .then(() => {
        setSubmissionsStatus("success")
        formHelper.reset()
      })
      .catch(() => {
        setSubmissionsStatus("failure")
        // Resets isDirty === false, but does not clear out values.
        // Necessary for allowing resubmission on submit error
        formHelper.reset(formData)
      })

  return (
    <main className="text-ms-dark-blue-80 p-8 bg-ms-cream-30 flex flex-row min-h-screen justify-center">
      <SubmissionSuccessModal
        message={data.confirmation_message}
        onClose={() => setSubmissionsStatus(undefined)}
        isOpen={submissionStatus === "success"}
      />
      <div className="max-w-4xl">
        {data.icon && (
          <div className="mb-3 sm:mb-8 max-w-full h-18 sm:h-24">
            <img
              className="object-contain max-w-full max-h-full"
              src={data.icon.href}
              alt={data.icon.name}
            />
          </div>
        )}
        <h2 className="text-[2.5rem] font-semibold mb-3">{data.form_header}</h2>
        {data.form_description && (
          <p className="font-semibold pb-4">{data.form_description}</p>
        )}
        <FormProvider {...formHelper}>
          <form onSubmit={formHelper.handleSubmit(onSubmit)}>
            <div className="flex mb-6 items-start sm:flex-row flex-col justify-between sm:items-center">
              <div className="mr-6 w-full sm:basis-[45%]">
                {data.attribute_fields.map(
                  ({
                    contact_attribute,
                    field_type,
                    default_field_value,
                    display_field_name,
                    top_level_field,
                    multi_choice_options,
                    visible,
                  }) => {
                    const field = top_level_field ?? String(contact_attribute)
                    return (
                      <FormField
                        key={field}
                        displayName={display_field_name}
                        field={field}
                        defaultValue={default_field_value}
                        fieldType={field_type}
                        isRequired={REQUIRED_FIELDS.includes(field)}
                        multiChoiceOptions={multi_choice_options}
                        visible={visible}
                      />
                    )
                  }
                )}
              </div>
              {data.form_image && (
                <div className="sm:mx-auto mx-6 self-center sm:self-auto">
                  <img
                    className="ml-auto max-h-[24rem]"
                    src={data.form_image.href}
                    alt={data.form_image.name}
                  />
                </div>
              )}
            </div>
            <ConsentCheckBox consentText={data.consent_text} />
            <PrivacyPolicy />
            {submissionStatus === "failure" ? (
              <div
                className="w-fit py-4 text-sm text-ms-error-red"
                role="alert"
              >
                <span className="font-medium">
                  Could not submit the form. Something went wrong.
                </span>
              </div>
            ) : (
              <input
                className="bg-ms-spark-red text-white cursor-pointer border-0 rounded-3xl py-2 px-8 mt-4 hover:bg-ms-spark-deep-red"
                type="submit"
                name="submit"
                value="LET'S GO"
              />
            )}
          </form>
        </FormProvider>
      </div>
    </main>
  )
}

async function formFetcher(URLPath: string): Promise<TFormFields> {
  return http
    .get(`get-form/`, {
      params: { url_path: URLPath },
    })
    .then((response) => response.data)
}

type SaveContactRequestPayload = Record<
  string,
  number | string | Record<string, number | string>[]
>

const mapFormDataToPayload =
  (formData: Record<string, number | string>) =>
  (preferenceId: number, attributeIds: ReadonlyArray<number | null>) => {
    const updatedFormData: SaveContactRequestPayload = { ...formData }
    updatedFormData.attribute_fields = []
    updatedFormData.preference_id = preferenceId
    for (const [key, value] of Object.entries(formData)) {
      if (attributeIds.includes(Number(key))) {
        if (value) {
          updatedFormData.attribute_fields.push({
            contact_attribute: key,
            value,
          })
        }
        delete updatedFormData[key]
      }
    }
    return updatedFormData
  }

function saveContact(payload: SaveContactRequestPayload) {
  return http.post("submit-form/", payload)
}

function getFieldValidation(fieldType: string, isRequired: boolean) {
  const validation: { [k: string]: string | Record<string, string | RegExp> } =
    {}
  if (isRequired) {
    validation["required"] = "Field is required"
  }
  if (fieldType === "PHONE") {
    validation["pattern"] = {
      value: /^[2-9][0-9]{9}$/,
      message: "Enter a valid phone number with just digits. Eg: 5552551234",
    }
  }
  if (fieldType === "DATE") {
    validation["pattern"] = {
      value: /^\d{1,2}\/\d{1,2}\/\d{2,4}$/,
      message:
        "Enter a valid date in the month/day/year format. Eg: 11/22/1999",
    }
  }
  if (fieldType === "URL") {
    validation["pattern"] = {
      value:
        /^(?:http(s)?:\/\/)?[\w\.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#%[\]@!\$&'\(\)\*\+,;=.]+$/,
      message: "Enter a valid URL. Eg: https://www.example.com",
    }
  }
  if (fieldType === "EMAIL") {
    validation["pattern"] = {
      value:
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: "Enter a valid email address",
    }
  }
  return validation
}
