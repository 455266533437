import { MainstayLogo } from "./MainstayLogo"

export function ErrorPage({ errorMessage }: { errorMessage: string }) {
  return (
    <div className="text-ms-dark-blue-80">
      <div className="bg-ms-dark-blue-80 h-100 w-100 d-flex py-7">
        <div className="m-auto max-w-4xl px-2">
          <MainstayLogo width={330} height={102} />
        </div>
      </div>
      <div className="m-auto max-w-4xl p-7">{errorMessage}</div>
    </div>
  )
}

export function NotFound() {
  return <ErrorPage errorMessage="Not found. Please confirm the URL." />
}
