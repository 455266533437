export function MainstayLogo({
  width,
  height,
  className,
}: {
  width: number
  height: number
  className?: string
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 256 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M89.6779 20.5329C94.993 20.5329 98.1723 24.2845 98.1723 30.2064V47.3265H91.0067V31.7311C91.0067 28.9148 89.7927 27.0408 87.3091 27.0408C85.4585 27.0408 83.7854 28.2124 83.3228 30.4394V47.3265H76.1015V31.7311C76.1015 28.9148 74.9467 27.0408 72.4596 27.0408C70.609 27.0408 68.8768 28.2124 68.4141 30.4394V47.3265H61.2485V21.1223H68.4141V23.2892C69.6838 21.6482 72.0561 20.5329 74.9432 20.5329C78.0042 20.5329 80.4322 21.9411 81.8757 23.8751C83.5524 21.9975 86.036 20.5329 89.6779 20.5329Z"
        fill="white"
      />
      <path
        d="M116.942 47.3266V45.9184C116.076 46.7972 113.474 47.7959 110.642 47.7959C105.442 47.7959 101.049 44.7467 101.049 39.2941C101.049 34.3109 105.442 30.9088 111.046 30.9088C113.3 30.9088 115.902 31.6711 116.938 32.4334V30.4994C116.938 28.2724 115.61 26.4549 112.719 26.4549C110.695 26.4549 109.484 27.3937 108.907 28.9183H102.089C102.84 24.168 107.06 20.5329 112.952 20.5329C119.829 20.5329 123.815 24.3445 123.815 30.6158V47.3266H116.942ZM116.942 38.2989C116.305 36.8943 114.34 36.012 112.26 36.012C110.006 36.012 107.696 37.0072 107.696 39.2376C107.696 41.5246 110.009 42.4633 112.26 42.4633C114.34 42.4633 116.305 41.5846 116.942 40.1764V38.2989Z"
        fill="white"
      />
      <path
        d="M131.336 17.8753C129.026 17.8753 127.061 16.1743 127.061 13.7144C127.061 11.311 129.026 9.55349 131.336 9.55349C133.59 9.55349 135.555 11.311 135.555 13.7144C135.552 16.1778 133.587 17.8753 131.336 17.8753ZM134.919 47.4852H127.753V21.2775H134.919V47.4852Z"
        fill="white"
      />
      <path
        d="M138.794 21.1222H145.959V23.5255C147.347 21.8845 149.89 20.5363 152.892 20.5363C158.729 20.5363 162.023 24.4078 162.023 30.2098V47.3299H154.857V31.7309C154.857 28.9746 153.469 27.0971 150.638 27.0971C148.443 27.0971 146.533 28.4452 145.956 30.7322V47.3229H138.79V21.1222H138.794Z"
        fill="white"
      />
      <path
        d="M171.094 39.5306C171.672 41.4046 173.289 42.4033 175.428 42.4033C177.623 42.4033 179.07 41.5246 179.07 39.8235C179.07 38.6518 178.26 38.0659 176.468 37.5966L171.268 36.4213C167.338 35.4826 164.565 33.4321 164.565 29.0347C164.565 23.9351 169.073 20.5329 174.851 20.5329C181.38 20.5329 184.963 23.6986 185.829 28.6254H179.011C178.549 27.1607 177.335 26.0455 174.966 26.0455C173.234 26.0455 171.557 26.8678 171.557 28.5089C171.557 29.5641 172.249 30.15 173.87 30.5594L179.185 31.791C183.693 32.9063 186.062 35.4261 186.062 39.4141C186.062 44.7502 181.498 47.9159 175.488 47.9159C169.188 47.9159 164.739 44.8667 163.988 39.5306H171.094Z"
        fill="white"
      />
      <path
        d="M191.565 26.9841H187.634V21.1222H191.565V14.0285H198.671V21.1222H204.334V26.9841H198.671V38.4752C198.671 40.5292 199.771 41.4644 201.618 41.4644C202.717 41.4644 203.931 41.1115 204.738 40.5857V47.0335C203.757 47.5593 202.021 47.8558 200.115 47.8558C194.856 47.8558 191.561 45.216 191.561 39.414V26.9841H191.565Z"
        fill="white"
      />
      <path
        d="M222.485 47.3266V45.9184C221.619 46.7972 219.017 47.7959 216.185 47.7959C210.985 47.7959 206.592 44.7467 206.592 39.2941C206.592 34.3109 210.985 30.9088 216.589 30.9088C218.843 30.9088 221.445 31.6711 222.481 32.4334V30.4994C222.481 28.2724 221.153 26.4549 218.262 26.4549C216.238 26.4549 215.027 27.3937 214.45 28.9183H207.632C208.383 24.168 212.603 20.5329 218.495 20.5329C225.372 20.5329 229.358 24.3445 229.358 30.6158V47.3266H222.485ZM222.485 38.2989C221.848 36.8943 219.883 36.012 217.803 36.012C215.549 36.012 213.239 37.0072 213.239 39.2376C213.239 41.5246 215.552 42.4633 217.803 42.4633C219.883 42.4633 221.848 41.5846 222.485 40.1764V38.2989Z"
        fill="white"
      />
      <path
        d="M235.484 57L239.241 47.4429L229.358 21.1222H236.931L242.883 38.5317L248.775 21.1222H256L242.535 57H235.484Z"
        fill="white"
      />
      <path
        d="M46.646 47.3265H9.20398C4.12196 47.3265 0 43.1479 0 37.9882V9.33824C0 4.18209 4.12196 0 9.20398 0H37.442C42.524 0 46.646 4.18209 46.646 9.33824V47.3265Z"
        fill="#F23661"
      />
      <path
        d="M40.0091 20.9422C39.8839 20.3422 39.3238 19.9575 38.736 20.0387L28.6102 21.4398L35.3688 10.5417C35.7549 9.89935 35.5741 9.0594 34.9479 8.64648C34.3427 8.24769 33.5426 8.39591 33.1078 8.96764L25.6083 18.74L23.0342 8.63943C22.8116 7.72889 21.9211 7.15364 21.0133 7.35127C20.1089 7.54538 19.5314 8.42767 19.681 9.34526L21.3646 19.5693L14.8842 15.5672C14.0911 15.0731 13.0546 15.3061 12.5432 16.1001C12.0319 16.8977 12.251 17.9635 13.0233 18.4929L19.194 22.7491C19.194 22.7491 8.94304 24.1749 8.87695 24.1926C7.32904 24.5278 6.34116 26.0701 6.67161 27.6406C7.00207 29.2111 8.52215 30.2134 10.0701 29.8781C10.1083 29.871 10.1466 29.8569 10.1848 29.8463L18.801 27.6088L13.6424 34.3319C13.5659 34.4272 13.4929 34.526 13.4268 34.6319C12.3937 36.2483 12.8459 38.4082 14.439 39.4563C16.0321 40.5045 18.1609 40.0457 19.194 38.4293C19.274 38.3058 19.3436 38.1752 19.4027 38.0446L23.1421 30.3122L24.0778 35.9483C24.37 37.427 25.7405 38.447 27.2397 38.2564C28.8085 38.0588 29.9216 36.6047 29.7268 35.0131C29.7059 34.8472 29.6711 34.6884 29.6259 34.5366L28.2102 28.957C28.2102 28.957 35.5741 34.032 35.5845 34.0355C36.6976 34.7696 38.1829 34.4484 38.9029 33.3191C39.6264 32.1932 39.3099 30.6828 38.1968 29.9522C38.1516 29.924 29.779 24.7572 29.779 24.7572L39.1986 22.3009C39.7725 22.1351 40.1378 21.5457 40.0091 20.9422Z"
        fill="white"
      />
      <path
        d="M61 73.6558V63.039H62.8044V67.3084H66.9265V63.039H68.7309V73.6558H66.9265V68.9481H62.8044V73.6558H61Z"
        fill="#E7EBF0"
      />
      <path
        d="M74.4254 73.8506C73.8965 73.8506 73.404 73.7587 72.9477 73.5747C72.4914 73.3799 72.0921 73.1039 71.7499 72.7468C71.4077 72.3896 71.1381 71.9567 70.9411 71.4481C70.7544 70.9286 70.6611 70.3387 70.6611 69.6786C70.6611 69.0292 70.7596 68.4448 70.9566 67.9253C71.1537 67.4058 71.4129 66.9675 71.7344 66.6104C72.0662 66.2532 72.4447 65.9773 72.8699 65.7825C73.2951 65.5877 73.7306 65.4903 74.1765 65.4903C74.695 65.4903 75.1513 65.5823 75.5454 65.7662C75.9395 65.9502 76.2661 66.21 76.5254 66.5455C76.795 66.881 76.9972 67.2814 77.132 67.7468C77.2668 68.2121 77.3342 68.7208 77.3342 69.2727C77.3342 69.4567 77.3239 69.6299 77.3031 69.7922C77.2928 69.9545 77.2772 70.0844 77.2565 70.1818H72.4033C72.4862 70.8961 72.7247 71.4481 73.1188 71.8377C73.5232 72.2164 74.0365 72.4058 74.6587 72.4058C74.9906 72.4058 75.2965 72.3571 75.5765 72.2597C75.8669 72.1515 76.152 72.0054 76.432 71.8214L77.0387 72.9903C76.6757 73.2392 76.2713 73.4448 75.8254 73.6071C75.3795 73.7695 74.9128 73.8506 74.4254 73.8506ZM72.3877 68.9156H75.7787C75.7787 68.2987 75.6491 67.8171 75.3898 67.4708C75.141 67.1136 74.7521 66.9351 74.2232 66.9351C73.7669 66.9351 73.3677 67.1028 73.0255 67.4383C72.6832 67.7738 72.4707 68.2662 72.3877 68.9156Z"
        fill="#E7EBF0"
      />
      <path
        d="M79.0427 73.6558V65.6851H80.5205L80.6449 67.0974H80.7071C80.9768 66.5779 81.3034 66.1829 81.6871 65.9123C82.0708 65.631 82.4649 65.4903 82.8693 65.4903C83.2323 65.4903 83.5226 65.5444 83.7404 65.6526L83.4293 67.276C83.2945 67.2327 83.17 67.2002 83.056 67.1786C82.9419 67.1569 82.8019 67.1461 82.636 67.1461C82.3352 67.1461 82.019 67.2706 81.6871 67.5195C81.3553 67.7576 81.0701 68.1797 80.8316 68.7857V73.6558H79.0427Z"
        fill="#E7EBF0"
      />
      <path
        d="M87.9602 73.8506C87.4313 73.8506 86.9388 73.7587 86.4825 73.5747C86.0262 73.3799 85.6269 73.1039 85.2847 72.7468C84.9425 72.3896 84.6729 71.9567 84.4759 71.4481C84.2892 70.9286 84.1959 70.3387 84.1959 69.6786C84.1959 69.0292 84.2944 68.4448 84.4914 67.9253C84.6884 67.4058 84.9477 66.9675 85.2692 66.6104C85.601 66.2532 85.9795 65.9773 86.4047 65.7825C86.8299 65.5877 87.2654 65.4903 87.7113 65.4903C88.2298 65.4903 88.6861 65.5823 89.0802 65.7662C89.4742 65.9502 89.8009 66.21 90.0602 66.5455C90.3298 66.881 90.532 67.2814 90.6668 67.7468C90.8016 68.2121 90.869 68.7208 90.869 69.2727C90.869 69.4567 90.8587 69.6299 90.8379 69.7922C90.8275 69.9545 90.812 70.0844 90.7912 70.1818H85.938C86.021 70.8961 86.2595 71.4481 86.6536 71.8377C87.058 72.2164 87.5713 72.4058 88.1935 72.4058C88.5254 72.4058 88.8313 72.3571 89.1113 72.2597C89.4017 72.1515 89.6868 72.0054 89.9668 71.8214L90.5735 72.9903C90.2105 73.2392 89.8061 73.4448 89.3602 73.6071C88.9143 73.7695 88.4476 73.8506 87.9602 73.8506ZM85.9225 68.9156H89.3135C89.3135 68.2987 89.1839 67.8171 88.9246 67.4708C88.6758 67.1136 88.2869 66.9351 87.758 66.9351C87.3017 66.9351 86.9025 67.1028 86.5603 67.4383C86.218 67.7738 86.0055 68.2662 85.9225 68.9156Z"
        fill="#E7EBF0"
      />
      <path
        d="M96.0027 73.6558V67.1623H94.9761V65.7662L96.0027 65.6851V64.8571C96.0027 64.4459 96.0494 64.0671 96.1427 63.7208C96.236 63.3636 96.3864 63.0606 96.5938 62.8117C96.8012 62.5519 97.0605 62.3517 97.3716 62.211C97.693 62.0703 98.0819 62 98.5382 62C98.8078 62 99.0619 62.0271 99.3004 62.0812C99.5493 62.1353 99.7619 62.2002 99.9382 62.276L99.5959 63.6721C99.3159 63.5422 99.036 63.4773 98.756 63.4773C98.113 63.4773 97.7915 63.9264 97.7915 64.8247V65.6851H99.2848V67.1623H97.7915V73.6558H96.0027Z"
        fill="#E7EBF0"
      />
      <path
        d="M103.603 73.8506C103.126 73.8506 102.67 73.7587 102.234 73.5747C101.799 73.3907 101.41 73.1201 101.068 72.763C100.736 72.4058 100.471 71.9675 100.274 71.4481C100.077 70.9286 99.9789 70.3387 99.9789 69.6786C99.9789 69.0184 100.077 68.4286 100.274 67.9091C100.471 67.3896 100.736 66.9513 101.068 66.5942C101.41 66.237 101.799 65.9664 102.234 65.7825C102.67 65.5877 103.126 65.4903 103.603 65.4903C104.08 65.4903 104.537 65.5877 104.972 65.7825C105.418 65.9664 105.807 66.237 106.139 66.5942C106.471 66.9513 106.735 67.3896 106.932 67.9091C107.139 68.4286 107.243 69.0184 107.243 69.6786C107.243 70.3387 107.139 70.9286 106.932 71.4481C106.735 71.9675 106.471 72.4058 106.139 72.763C105.807 73.1201 105.418 73.3907 104.972 73.5747C104.537 73.7587 104.08 73.8506 103.603 73.8506ZM103.603 72.3247C104.163 72.3247 104.604 72.0866 104.925 71.6104C105.247 71.1234 105.408 70.4794 105.408 69.6786C105.408 68.8669 105.247 68.2229 104.925 67.7468C104.604 67.2597 104.163 67.0162 103.603 67.0162C103.043 67.0162 102.602 67.2597 102.281 67.7468C101.97 68.2229 101.814 68.8669 101.814 69.6786C101.814 70.4794 101.97 71.1234 102.281 71.6104C102.602 72.0866 103.043 72.3247 103.603 72.3247Z"
        fill="#E7EBF0"
      />
      <path
        d="M109.014 73.6558V65.6851H110.491L110.616 67.0974H110.678C110.948 66.5779 111.274 66.1829 111.658 65.9123C112.042 65.631 112.436 65.4903 112.84 65.4903C113.203 65.4903 113.494 65.5444 113.711 65.6526L113.4 67.276C113.265 67.2327 113.141 67.2002 113.027 67.1786C112.913 67.1569 112.773 67.1461 112.607 67.1461C112.306 67.1461 111.99 67.2706 111.658 67.5195C111.326 67.7576 111.041 68.1797 110.803 68.7857V73.6558H109.014Z"
        fill="#E7EBF0"
      />
      <path
        d="M120.622 73.8506C120.176 73.8506 119.797 73.7803 119.486 73.6396C119.185 73.4989 118.936 73.3041 118.739 73.0552C118.553 72.7955 118.413 72.4924 118.319 72.1461C118.237 71.789 118.195 71.3939 118.195 70.961V67.1623H117.106V65.7662L118.288 65.6851L118.506 63.5097H119.999V65.6851H121.944V67.1623H119.999V70.961C119.999 71.8918 120.357 72.3571 121.073 72.3571C121.208 72.3571 121.342 72.3409 121.477 72.3084C121.622 72.276 121.752 72.2327 121.866 72.1786L122.177 73.5584C121.97 73.6342 121.731 73.6991 121.462 73.7532C121.202 73.8182 120.922 73.8506 120.622 73.8506Z"
        fill="#E7EBF0"
      />
      <path
        d="M123.551 73.6558V62.1948H125.34V65.1494L125.278 66.6916C125.599 66.3777 125.952 66.1017 126.335 65.8636C126.73 65.6147 127.191 65.4903 127.72 65.4903C128.539 65.4903 129.135 65.7662 129.509 66.3182C129.882 66.8593 130.069 67.6385 130.069 68.6558V73.6558H128.28V68.8994C128.28 68.2392 128.187 67.7738 128 67.5032C127.813 67.2327 127.507 67.0974 127.082 67.0974C126.75 67.0974 126.455 67.184 126.195 67.3571C125.947 67.5195 125.661 67.763 125.34 68.0877V73.6558H123.551Z"
        fill="#E7EBF0"
      />
      <path
        d="M135.491 73.8506C134.963 73.8506 134.47 73.7587 134.014 73.5747C133.557 73.3799 133.158 73.1039 132.816 72.7468C132.474 72.3896 132.204 71.9567 132.007 71.4481C131.82 70.9286 131.727 70.3387 131.727 69.6786C131.727 69.0292 131.826 68.4448 132.023 67.9253C132.22 67.4058 132.479 66.9675 132.8 66.6104C133.132 66.2532 133.511 65.9773 133.936 65.7825C134.361 65.5877 134.797 65.4903 135.243 65.4903C135.761 65.4903 136.217 65.5823 136.611 65.7662C137.006 65.9502 137.332 66.21 137.591 66.5455C137.861 66.881 138.063 67.2814 138.198 67.7468C138.333 68.2121 138.4 68.7208 138.4 69.2727C138.4 69.4567 138.39 69.6299 138.369 69.7922C138.359 69.9545 138.343 70.0844 138.323 70.1818H133.469C133.552 70.8961 133.791 71.4481 134.185 71.8377C134.589 72.2164 135.103 72.4058 135.725 72.4058C136.057 72.4058 136.363 72.3571 136.643 72.2597C136.933 72.1515 137.218 72.0054 137.498 71.8214L138.105 72.9903C137.742 73.2392 137.337 73.4448 136.891 73.6071C136.446 73.7695 135.979 73.8506 135.491 73.8506ZM133.454 68.9156H136.845C136.845 68.2987 136.715 67.8171 136.456 67.4708C136.207 67.1136 135.818 66.9351 135.289 66.9351C134.833 66.9351 134.434 67.1028 134.092 67.4383C133.749 67.7738 133.537 68.2662 133.454 68.9156Z"
        fill="#E7EBF0"
      />
      <path
        d="M142.616 77C142.336 77 142.098 76.9784 141.901 76.9351C141.693 76.8918 141.517 76.8431 141.372 76.789L141.714 75.3929C141.818 75.4253 141.927 75.4524 142.041 75.474C142.144 75.5065 142.253 75.5227 142.367 75.5227C142.71 75.5227 142.938 75.3983 143.052 75.1494C143.176 74.9113 143.238 74.5595 143.238 74.0942V65.6851H145.043V74.0617C145.043 74.4838 145.001 74.8734 144.918 75.2305C144.835 75.5877 144.695 75.8961 144.498 76.1558C144.312 76.4264 144.063 76.632 143.752 76.7727C143.441 76.9242 143.062 77 142.616 77ZM144.141 64.289C143.83 64.289 143.565 64.1916 143.347 63.9968C143.14 63.8019 143.036 63.5476 143.036 63.2338C143.036 62.9199 143.14 62.6656 143.347 62.4708C143.565 62.2652 143.83 62.1623 144.141 62.1623C144.462 62.1623 144.727 62.2652 144.934 62.4708C145.152 62.6656 145.261 62.9199 145.261 63.2338C145.261 63.5476 145.152 63.8019 144.934 63.9968C144.727 64.1916 144.462 64.289 144.141 64.289Z"
        fill="#E7EBF0"
      />
      <path
        d="M150.436 73.8506C149.959 73.8506 149.502 73.7587 149.067 73.5747C148.631 73.3907 148.242 73.1201 147.9 72.763C147.568 72.4058 147.304 71.9675 147.107 71.4481C146.91 70.9286 146.811 70.3387 146.811 69.6786C146.811 69.0184 146.91 68.4286 147.107 67.9091C147.304 67.3896 147.568 66.9513 147.9 66.5942C148.242 66.237 148.631 65.9664 149.067 65.7825C149.502 65.5877 149.959 65.4903 150.436 65.4903C150.913 65.4903 151.369 65.5877 151.805 65.7825C152.25 65.9664 152.639 66.237 152.971 66.5942C153.303 66.9513 153.567 67.3896 153.765 67.9091C153.972 68.4286 154.076 69.0184 154.076 69.6786C154.076 70.3387 153.972 70.9286 153.765 71.4481C153.567 71.9675 153.303 72.4058 152.971 72.763C152.639 73.1201 152.25 73.3907 151.805 73.5747C151.369 73.7587 150.913 73.8506 150.436 73.8506ZM150.436 72.3247C150.996 72.3247 151.436 72.0866 151.758 71.6104C152.079 71.1234 152.24 70.4794 152.24 69.6786C152.24 68.8669 152.079 68.2229 151.758 67.7468C151.436 67.2597 150.996 67.0162 150.436 67.0162C149.876 67.0162 149.435 67.2597 149.114 67.7468C148.802 68.2229 148.647 68.8669 148.647 69.6786C148.647 70.4794 148.802 71.1234 149.114 71.6104C149.435 72.0866 149.876 72.3247 150.436 72.3247Z"
        fill="#E7EBF0"
      />
      <path
        d="M158.117 73.8506C157.298 73.8506 156.702 73.5801 156.328 73.039C155.955 72.487 155.768 71.7024 155.768 70.6851V65.6851H157.557V70.4416C157.557 71.1017 157.651 71.5671 157.837 71.8377C158.024 72.1082 158.325 72.2435 158.739 72.2435C159.071 72.2435 159.362 72.1569 159.611 71.9838C159.87 71.8106 160.145 71.5292 160.435 71.1396V65.6851H162.224V73.6558H160.762L160.622 72.487H160.575C160.243 72.8983 159.88 73.2284 159.486 73.4773C159.102 73.7262 158.646 73.8506 158.117 73.8506Z"
        fill="#E7EBF0"
      />
      <path
        d="M164.49 73.6558V65.6851H165.967L166.092 67.0974H166.154C166.424 66.5779 166.75 66.1829 167.134 65.9123C167.518 65.631 167.912 65.4903 168.316 65.4903C168.679 65.4903 168.97 65.5444 169.187 65.6526L168.876 67.276C168.741 67.2327 168.617 67.2002 168.503 67.1786C168.389 67.1569 168.249 67.1461 168.083 67.1461C167.782 67.1461 167.466 67.2706 167.134 67.5195C166.802 67.7576 166.517 68.1797 166.278 68.7857V73.6558H164.49Z"
        fill="#E7EBF0"
      />
      <path
        d="M170.292 73.6558V65.6851H171.77L171.895 66.7565H171.957C172.299 66.4102 172.667 66.1126 173.061 65.8636C173.466 65.6147 173.932 65.4903 174.461 65.4903C175.28 65.4903 175.877 65.7662 176.25 66.3182C176.623 66.8593 176.81 67.6385 176.81 68.6558V73.6558H175.021V68.8994C175.021 68.2392 174.928 67.7738 174.741 67.5032C174.555 67.2327 174.249 67.0974 173.823 67.0974C173.492 67.0974 173.196 67.184 172.937 67.3571C172.688 67.5195 172.403 67.763 172.081 68.0877V73.6558H170.292Z"
        fill="#E7EBF0"
      />
      <path
        d="M182.263 73.8506C181.734 73.8506 181.242 73.7587 180.785 73.5747C180.329 73.3799 179.93 73.1039 179.588 72.7468C179.246 72.3896 178.976 71.9567 178.779 71.4481C178.592 70.9286 178.499 70.3387 178.499 69.6786C178.499 69.0292 178.597 68.4448 178.794 67.9253C178.991 67.4058 179.251 66.9675 179.572 66.6104C179.904 66.2532 180.283 65.9773 180.708 65.7825C181.133 65.5877 181.568 65.4903 182.014 65.4903C182.533 65.4903 182.989 65.5823 183.383 65.7662C183.777 65.9502 184.104 66.21 184.363 66.5455C184.633 66.881 184.835 67.2814 184.97 67.7468C185.105 68.2121 185.172 68.7208 185.172 69.2727C185.172 69.4567 185.162 69.6299 185.141 69.7922C185.131 69.9545 185.115 70.0844 185.094 70.1818H180.241C180.324 70.8961 180.563 71.4481 180.957 71.8377C181.361 72.2164 181.874 72.4058 182.497 72.4058C182.828 72.4058 183.134 72.3571 183.414 72.2597C183.705 72.1515 183.99 72.0054 184.27 71.8214L184.876 72.9903C184.514 73.2392 184.109 73.4448 183.663 73.6071C183.217 73.7695 182.751 73.8506 182.263 73.8506ZM180.226 68.9156H183.617C183.617 68.2987 183.487 67.8171 183.228 67.4708C182.979 67.1136 182.59 66.9351 182.061 66.9351C181.605 66.9351 181.205 67.1028 180.863 67.4383C180.521 67.7738 180.308 68.2662 180.226 68.9156Z"
        fill="#E7EBF0"
      />
      <path
        d="M187.394 76.9351C187.186 76.9351 187.005 76.9188 186.849 76.8864C186.694 76.8647 186.544 76.8268 186.398 76.7727L186.725 75.3117C186.798 75.3333 186.881 75.355 186.974 75.3766C187.078 75.4091 187.176 75.4253 187.269 75.4253C187.674 75.4253 187.995 75.2955 188.234 75.0357C188.483 74.7868 188.669 74.4621 188.794 74.0617L188.934 73.5584L185.932 65.6851H187.752L189.074 69.5974C189.188 69.9437 189.297 70.3117 189.4 70.7013C189.515 71.0801 189.629 71.4589 189.743 71.8377H189.805C189.898 71.4697 189.992 71.0963 190.085 70.7175C190.189 70.3279 190.287 69.9545 190.38 69.5974L191.532 65.6851H193.258L190.489 74.013C190.334 74.4675 190.163 74.8734 189.976 75.2305C189.789 75.5877 189.572 75.8907 189.323 76.1396C189.084 76.3993 188.804 76.5942 188.483 76.724C188.172 76.8647 187.809 76.9351 187.394 76.9351Z"
        fill="#E7EBF0"
      />
      <path
        d="M194.849 73.8506C194.517 73.8506 194.237 73.7316 194.009 73.4935C193.791 73.2554 193.682 72.9524 193.682 72.5844C193.682 72.2164 193.791 71.9134 194.009 71.6753C194.237 71.4372 194.517 71.3182 194.849 71.3182C195.181 71.3182 195.456 71.4372 195.673 71.6753C195.891 71.9134 196 72.2164 196 72.5844C196 72.9524 195.891 73.2554 195.673 73.4935C195.456 73.7316 195.181 73.8506 194.849 73.8506Z"
        fill="#E7EBF0"
      />
    </svg>
  )
}
